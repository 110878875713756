/*------------------------------------*\
    #IMPORTS PRE_RENDER — Must be after reset
\*------------------------------------*/

//@import "libs/required";
@import "libs/reset";
@import "libs/settings";
@import "libs/mixins";
@import "libs/grid-mixins";
@import "libs/placeholders";

// Bootstrap forms
@import "libs/bootstrap/_variables";
@import "libs/bootstrap/mixins/_buttons";
@import "libs/bootstrap/mixins/_size";
@import "libs/bootstrap/mixins/_tab-focus";
@import "libs/bootstrap/mixins/_forms";
@import "libs/bootstrap/mixins/_table-row";
@import "libs/bootstrap/mixins/_vendor-prefixes";
@import "libs/bootstrap/_button-groups";
@import "libs/bootstrap/_buttons";
@import "libs/bootstrap/_forms";
@import "libs/bootstrap/_input-groups";
@import "libs/bootstrap/_tables";

@import "libs/typography";            // Base level typography
@import "libs/forms";                 // Forms
@import "add-ons/module-typeset";     // Specific typesetting
@import "libs/grid";                  // Grid system

// Use SASS globbing to import all _.scss files in the module folder.
// These should be independent modules that follow the BEM/SMACSS way

@import "modules/_00-00-temp-patternlab.scss";
@import "modules/_00-01-responsive-nav.scss";
@import "modules/_00-02-hamburger-icon.scss";
@import "modules/_00-03-flickity.scss";
@import "modules/_01-atoms.scss";
@import "modules/_02-organisms.scss";
@import "modules/_03-templates.scss";



/*------------------------------------*\
    #BASE-STYLES
\*------------------------------------*/


html {
  font-size: 100%;
  @include respond-to-max(768px, $IE9: false) { // Stop at precise width, as we're talking about devices
    -webkit-text-size-adjust: 100%; // Stops text scaling in iOS.
  }
}

body {
  @extend .font-body;
  background: $body-color;
  color: $base-color;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/*------------------------------------*\
    #IMPORT-STYLES
\*------------------------------------*/



/*------------------------------------*\
    #PRINT
\*------------------------------------*/


@media print {
  body {
    font-size: percentage(10 / 16);
  }
}
