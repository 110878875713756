/*! responsive-nav.js 1.0.39 by @viljamis */

@media (max-width: $screen-xs-max) {
  .nav-collapse ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;
    list-style: none;
  }

  .nav-collapse li {
    @media screen and (max-width: $screen-xs-max) {
      width: 100%;
      display: block;
    }
  }

  .js .nav-collapse {
    clip: rect(0 0 0 0);
    max-height: 0;
    position: absolute;
    display: block;
    overflow: hidden;
    zoom: 1;
  }

  .nav-collapse.opened {
    max-height: 9999px;
  }

  .nav-toggle {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    display: none;
  }
}



@media screen and (min-width: $screen-sm-min) {
  // .js .nav-collapse {
  //   position: relative;
  // }
  // .js .nav-collapse.closed {
  //   max-height: none;
  // }
  .nav-toggle {
    display: none;
  }
}
