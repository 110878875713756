/*------------------------------------*\
    #HAMBURGER ICON
\*------------------------------------*/

$button-width: 50px;                    // The width of the button area
$button-height: 50px;                   // The height of the button area
$bar-thickness: 2px;                    // The thickness of the button bars
$button-pad: 0px;                      // The left/right padding between button area and bars.
$button-bar-space: 10px;                // The spacing between button bars
$button-transistion-duration: 0.2s;     // The transition duration


.hamburger {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: $button-width;
  height: $button-height;
  @media (max-width: $screen-xs-max) {
    width: $button-width/1.5;
    height: $button-height/1.5;
  }
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background $button-transistion-duration;
  background-color: transparent;
}

.hamburger:focus {
  outline: none;
}

.hamburger span {
  display: block;
  position: absolute;
  top: ($button-height / 2) - ($bar-thickness / 2);
  left: $button-pad;
  right: $button-pad;
  height: $bar-thickness;
  @media (max-width: $screen-xs-max) {
    top: ($button-height / 2)/1.5 - ($bar-thickness / 2)/1.5;
    left: $button-pad/1.5;
    right: $button-pad/1.5;
    height: $bar-thickness/1.5;
  }
  background: $base-color;
}

.hamburger span::before,
.hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: $bar-thickness;
  @media (max-width: $screen-xs-max) {
    height: $bar-thickness/1.5;
  }
  background-color: $base-color;
  content: "";
}

.hamburger span::before {
  top: -$bar-thickness - $button-bar-space;
  @media (max-width: $screen-xs-max) {
    top: -$bar-thickness/1.5 - $button-bar-space/1.5;
  }
}

.hamburger span::after {
  bottom: -$bar-thickness - $button-bar-space;
  @media (max-width: $screen-xs-max) {
    bottom: -$bar-thickness/1.5 - $button-bar-space/1.5;
  }
}

.hamburger--x span {
  transition: background 0s $button-transistion-duration;
}

.hamburger--x span::before,
.hamburger--x span::after {
  transition-duration: $button-transistion-duration, $button-transistion-duration;
  transition-delay: $button-transistion-duration, 0s;
}

.hamburger--x span::before {
  transition-property: top, transform;
}

.hamburger--x span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */

.hamburger--x.active span {
  background: none;
}

.hamburger--x.active span::before {
  top: 0;
  transform: rotate(45deg);
}

.hamburger--x.active span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.hamburger--x.active span::before,
.hamburger--x.active span::after {
  transition-delay: 0s, $button-transistion-duration;
  background: $base-color;
}

.hamburger {
  position: absolute;
  top: 20px;
  right: 0;
  @media (min-width: $screen-sm-min) {
    display: none;
  }
}
