@charset "UTF-8";
/*------------------------------------*    #IMPORTS PRE_RENDER — Must be after reset
\*------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,700&subset=latin-ext");
/* line 4, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
*, *:before, *:after {
  box-sizing: border-box; }

/* line 6, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* line 7, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 8, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

/* line 9, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
q, blockquote {
  quotes: none; }

/* line 10, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

/* line 11, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
a img {
  border: none; }

/* line 12, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

/* line 18, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
button, input {
  line-height: normal; }

/* line 19, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

/* line 20, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  *overflow: visible; }

/* line 21, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* line 22, /Users/constant/Repos/agr-website/assets/css/libs/_reset.scss */
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/*------------------------------------*    #COLOURS
\*------------------------------------*/
/*------------------------------------*    #BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*    #SPACES
\*------------------------------------*/
/*------------------------------------*    #BORDERS WIDTH
\*------------------------------------*/
/*------------------------------------*    #FUNCTIONAL STUFF
\*------------------------------------*/
/* line 6, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle; }
  /* line 11, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    float: left; }
    /* line 15, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
    .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 2; }

/* line 26, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px; }

/* line 35, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-toolbar {
  margin-left: -5px; }
  /* line 6, /Users/constant/Repos/agr-website/assets/css/libs/_mixins.scss */
  .btn-toolbar:after {
    content: "";
    display: table;
    clear: both; }
  /* line 39, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
  .btn-toolbar .btn,
  .btn-toolbar .btn-group,
  .btn-toolbar .input-group {
    float: left; }
  /* line 44, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
  .btn-toolbar > .btn,
  .btn-toolbar > .btn-group,
  .btn-toolbar > .input-group {
    margin-left: 5px; }

/* line 51, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

/* line 56, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group > .btn:first-child {
  margin-left: 0; }
  /* line 58, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }

/* line 63, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

/* line 69, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group > .btn-group {
  float: left; }

/* line 72, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

/* line 76, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

/* line 81, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

/* line 86, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0; }

/* line 105, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px; }

/* line 109, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px; }

/* line 116, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  /* line 120, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
  .btn-group.open .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none; }

/* line 127, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn .caret {
  margin-left: 0; }

/* line 131, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-lg .caret, .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0; }

/* line 136, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px; }

/* line 145, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%; }

/* line 6, /Users/constant/Repos/agr-website/assets/css/libs/_mixins.scss */
.btn-group-vertical > .btn-group:after {
  content: "";
  display: table;
  clear: both; }

/* line 157, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-vertical > .btn-group > .btn {
  float: none; }

/* line 162, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

/* line 172, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

/* line 175, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

/* line 179, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

/* line 188, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

/* line 193, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

/* line 201, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }
  /* line 206, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
  .btn-group-justified > .btn,
  .btn-group-justified > .btn-group {
    float: none;
    display: table-cell;
    width: 1%; }
  /* line 212, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
  .btn-group-justified > .btn-group .btn {
    width: 100%; }
  /* line 216, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
  .btn-group-justified > .btn-group .dropdown-menu {
    left: auto; }

/* line 237, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_button-groups.scss */
[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

/* line 9, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  /* line 26, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
  .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  /* line 32, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
  .btn:hover, .btn:focus, .btn.focus {
    color: #333;
    text-decoration: none; }
  /* line 39, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
  .btn:active, .btn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  /* line 46, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
  .btn.disabled, .btn[disabled],
  fieldset[disabled] .btn {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }

/* line 58, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

/* line 68, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }
  /* line 11, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-default:focus, .btn-default.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c; }
  /* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
  /* line 22, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
    /* line 29, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
    .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus,
    .open > .btn-default.dropdown-toggle:hover,
    .open > .btn-default.dropdown-toggle:focus,
    .open > .btn-default.dropdown-toggle.focus {
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c; }
  /* line 37, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    background-image: none; }
  /* line 45, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus,
  fieldset[disabled] .btn-default:hover,
  fieldset[disabled] .btn-default:focus,
  fieldset[disabled] .btn-default.focus {
    background-color: #fff;
    border-color: #ccc; }
  /* line 53, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-default .badge {
    color: #fff;
    background-color: #333; }

/* line 71, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4; }
  /* line 11, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40; }
  /* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74; }
  /* line 22, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #286090;
    border-color: #204d74; }
    /* line 29, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
    .btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus,
    .open > .btn-primary.dropdown-toggle:hover,
    .open > .btn-primary.dropdown-toggle:focus,
    .open > .btn-primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40; }
  /* line 37, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    background-image: none; }
  /* line 45, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus {
    background-color: #337ab7;
    border-color: #2e6da4; }
  /* line 53, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-primary .badge {
    color: #337ab7;
    background-color: #fff; }

/* line 75, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c; }
  /* line 11, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625; }
  /* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
  /* line 22, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
    /* line 29, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
    .btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus,
    .open > .btn-success.dropdown-toggle:hover,
    .open > .btn-success.dropdown-toggle:focus,
    .open > .btn-success.dropdown-toggle.focus {
      color: #fff;
      background-color: #398439;
      border-color: #255625; }
  /* line 37, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    background-image: none; }
  /* line 45, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus {
    background-color: #5cb85c;
    border-color: #4cae4c; }
  /* line 53, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-success .badge {
    color: #5cb85c;
    background-color: #fff; }

/* line 79, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da; }
  /* line 11, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85; }
  /* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
  /* line 22, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
    /* line 29, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
    .btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus,
    .open > .btn-info.dropdown-toggle:hover,
    .open > .btn-info.dropdown-toggle:focus,
    .open > .btn-info.dropdown-toggle.focus {
      color: #fff;
      background-color: #269abc;
      border-color: #1b6d85; }
  /* line 37, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    background-image: none; }
  /* line 45, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus {
    background-color: #5bc0de;
    border-color: #46b8da; }
  /* line 53, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-info .badge {
    color: #5bc0de;
    background-color: #fff; }

/* line 83, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236; }
  /* line 11, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d; }
  /* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-warning:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
  /* line 22, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
    /* line 29, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
    .btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus,
    .open > .btn-warning.dropdown-toggle:hover,
    .open > .btn-warning.dropdown-toggle:focus,
    .open > .btn-warning.dropdown-toggle.focus {
      color: #fff;
      background-color: #d58512;
      border-color: #985f0d; }
  /* line 37, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    background-image: none; }
  /* line 45, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus {
    background-color: #f0ad4e;
    border-color: #eea236; }
  /* line 53, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff; }

/* line 87, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; }
  /* line 11, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19; }
  /* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
  /* line 22, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
    /* line 29, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
    .btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus,
    .open > .btn-danger.dropdown-toggle:hover,
    .open > .btn-danger.dropdown-toggle:focus,
    .open > .btn-danger.dropdown-toggle.focus {
      color: #fff;
      background-color: #ac2925;
      border-color: #761c19; }
  /* line 37, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    background-image: none; }
  /* line 45, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus {
    background-color: #d9534f;
    border-color: #d43f3a; }
  /* line 53, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_buttons.scss */
  .btn-danger .badge {
    color: #d9534f;
    background-color: #fff; }

/* line 96, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-link {
  color: #337ab7;
  font-weight: normal;
  border-radius: 0; }
  /* line 101, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
  .btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled],
  fieldset[disabled] .btn-link {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none; }
  /* line 109, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
  .btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
    border-color: transparent; }
  /* line 115, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
  .btn-link:hover, .btn-link:focus {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent; }
  /* line 123, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
  .btn-link[disabled]:hover, .btn-link[disabled]:focus,
  fieldset[disabled] .btn-link:hover,
  fieldset[disabled] .btn-link:focus {
    color: #777777;
    text-decoration: none; }

/* line 135, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-lg, .btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

/* line 139, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-sm, .btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

/* line 143, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

/* line 151, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-block {
  display: block;
  width: 100%; }

/* line 157, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
.btn-block + .btn-block {
  margin-top: 5px; }

/* line 165, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_buttons.scss */
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

/* line 10, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

/* line 20, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

/* line 32, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold; }

/* line 47, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* line 52, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

/* line 59, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
input[type="file"] {
  display: block; }

/* line 64, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
input[type="range"] {
  display: block;
  width: 100%; }

/* line 70, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
select[multiple],
select[size] {
  height: auto; }

/* line 76, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

/* line 83, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555; }

/* line 114, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  /* line 57, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  /* line 103, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_vendor-prefixes.scss */
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  /* line 107, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_vendor-prefixes.scss */
  .form-control:-ms-input-placeholder {
    color: #999; }
  /* line 108, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_vendor-prefixes.scss */
  .form-control::-webkit-input-placeholder {
    color: #999; }
  /* line 136, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-control::-ms-expand {
    border: 0;
    background-color: transparent; }
  /* line 146, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-control[disabled], .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1; }
  /* line 153, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed; }

/* line 162, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
textarea.form-control {
  height: auto; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* line 193, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px; }
  /* line 197, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  input[type="date"].input-sm, .input-group-sm > input[type="date"].form-control,
  .input-group-sm > input[type="date"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="date"].btn,
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm > input[type="time"].form-control,
  .input-group-sm > input[type="time"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="time"].btn,
  .input-group-sm
  input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm > input[type="datetime-local"].form-control,
  .input-group-sm > input[type="datetime-local"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="datetime-local"].btn,
  .input-group-sm
  input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm > input[type="month"].form-control,
  .input-group-sm > input[type="month"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="month"].btn,
  .input-group-sm
  input[type="month"] {
    line-height: 30px; }
  /* line 202, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  input[type="date"].input-lg, .input-group-lg > input[type="date"].form-control,
  .input-group-lg > input[type="date"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="date"].btn,
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg > input[type="time"].form-control,
  .input-group-lg > input[type="time"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="time"].btn,
  .input-group-lg
  input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg > input[type="datetime-local"].form-control,
  .input-group-lg > input[type="datetime-local"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="datetime-local"].btn,
  .input-group-lg
  input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg > input[type="month"].form-control,
  .input-group-lg > input[type="month"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="month"].btn,
  .input-group-lg
  input[type="month"] {
    line-height: 46px; } }

/* line 215, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-group {
  margin-bottom: 15px; }

/* line 224, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }
  /* line 231, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .radio label,
  .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer; }

/* line 239, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

/* line 248, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

/* line 254, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

/* line 264, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

/* line 276, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
input[type="radio"][disabled], input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled]
input[type="checkbox"] {
  cursor: not-allowed; }

/* line 285, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled]
.checkbox-inline {
  cursor: not-allowed; }

/* line 295, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled]
.checkbox label {
  cursor: not-allowed; }

/* line 307, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px; }
  /* line 315, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-control-static.input-lg, .input-group-lg > .form-control-static.form-control,
  .input-group-lg > .form-control-static.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control,
  .input-group-sm > .form-control-static.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-static.btn {
    padding-left: 0;
    padding-right: 0; }

/* line 71, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.input-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

/* line 79, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
select.input-sm, .input-group-sm > select.form-control,
.input-group-sm > select.input-group-addon,
.input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px; }

/* line 84, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
textarea.input-sm, .input-group-sm > textarea.form-control,
.input-group-sm > textarea.input-group-addon,
.input-group-sm > .input-group-btn > textarea.btn,
select[multiple].input-sm,
.input-group-sm > select[multiple].form-control,
.input-group-sm > select[multiple].input-group-addon,
.input-group-sm > .input-group-btn > select[multiple].btn {
  height: auto; }

/* line 333, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

/* line 340, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

/* line 344, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

/* line 348, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

/* line 71, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.input-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

/* line 79, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
select.input-lg, .input-group-lg > select.form-control,
.input-group-lg > select.input-group-addon,
.input-group-lg > .input-group-btn > select.btn {
  height: 46px;
  line-height: 46px; }

/* line 84, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
textarea.input-lg, .input-group-lg > textarea.form-control,
.input-group-lg > textarea.input-group-addon,
.input-group-lg > .input-group-btn > textarea.btn,
select[multiple].input-lg,
.input-group-lg > select[multiple].form-control,
.input-group-lg > select[multiple].input-group-addon,
.input-group-lg > .input-group-btn > select[multiple].btn {
  height: auto; }

/* line 359, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

/* line 366, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

/* line 370, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

/* line 374, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333; }

/* line 388, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.has-feedback {
  position: relative; }
  /* line 393, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .has-feedback .form-control {
    padding-right: 42.5px; }

/* line 398, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

/* line 410, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback,
.input-group-lg > .input-group-addon + .form-control-feedback,
.input-group-lg > .input-group-btn > .btn + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

/* line 417, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback,
.input-group-sm > .input-group-addon + .form-control-feedback,
.input-group-sm > .input-group-btn > .btn + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

/* line 8, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d; }

/* line 21, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  /* line 24, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
  .has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

/* line 31, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8; }

/* line 37, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-success .form-control-feedback {
  color: #3c763d; }

/* line 8, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b; }

/* line 21, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  /* line 24, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
  .has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

/* line 31, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3; }

/* line 37, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-warning .form-control-feedback {
  color: #8a6d3b; }

/* line 8, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442; }

/* line 21, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  /* line 24, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
  .has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

/* line 31, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede; }

/* line 37, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_forms.scss */
.has-error .form-control-feedback {
  color: #a94442; }

/* line 439, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.has-feedback label ~ .form-control-feedback {
  top: 25px; }

/* line 442, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

/* line 453, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 768px) {
  /* line 478, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  /* line 485, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  /* line 492, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-inline .form-control-static {
    display: inline-block; }
  /* line 496, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
    /* line 500, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
      width: auto; }
  /* line 508, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-inline .input-group > .form-control {
    width: 100%; }
  /* line 512, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  /* line 519, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
    /* line 526, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
    .form-inline .radio label,
    .form-inline .checkbox label {
      padding-left: 0; }
  /* line 530, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  /* line 537, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

/* line 559, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px; }

/* line 569, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px; }

/* line 575, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-horizontal .form-group {
  margin-left: -1.5%;
  margin-right: -1.5%; }
  /* line 6, /Users/constant/Repos/agr-website/assets/css/libs/_mixins.scss */
  .form-horizontal .form-group:after {
    content: "";
    display: table;
    clear: both; }

@media (min-width: 768px) {
  /* line 582, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px; } }

/* line 593, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
.form-horizontal .has-feedback .form-control-feedback {
  right: 1%; }

@media (min-width: 768px) {
  /* line 603, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px; } }

@media (min-width: 768px) {
  /* line 611, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_forms.scss */
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

/* line 7, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }
  /* line 13, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
  .input-group[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0; }
  /* line 19, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
  .input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0; }
    /* line 33, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
    .input-group .form-control:focus {
      z-index: 3; }

/* line 58, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell; }
  /* line 63, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

/* line 68, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; }

/* line 77, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555555;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 4px; }
  /* line 89, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
  .input-group-addon.input-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px; }
  /* line 94, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
  .input-group-addon.input-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px; }
  /* line 101, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

/* line 108, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

/* line 117, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
.input-group-addon:first-child {
  border-right: 0; }

/* line 120, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

/* line 129, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
.input-group-addon:last-child {
  border-left: 0; }

/* line 135, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  /* line 144, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
  .input-group-btn > .btn {
    position: relative; }
    /* line 146, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
    .input-group-btn > .btn + .btn {
      margin-left: -1px; }
    /* line 150, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
    .input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
      z-index: 2; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group {
    margin-right: -1px; }
  /* line 165, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_input-groups.scss */
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group {
    z-index: 2;
    margin-left: -1px; }

/* line 6, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
table {
  background-color: transparent; }

/* line 9, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left; }

/* line 15, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
th {
  text-align: left; }

/* line 22, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }
  /* line 31, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
  .table > thead > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > th,
  .table > tbody > tr > td,
  .table > tfoot > tr > th,
  .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    border-top: 1px solid #ddd; }
  /* line 41, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
  .table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd; }
  /* line 50, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
  .table > caption + thead > tr:first-child > th,
  .table > caption + thead > tr:first-child > td,
  .table > colgroup + thead > tr:first-child > th,
  .table > colgroup + thead > tr:first-child > td,
  .table > thead:first-child > tr:first-child > th,
  .table > thead:first-child > tr:first-child > td {
    border-top: 0; }
  /* line 57, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
  .table > tbody + tbody {
    border-top: 2px solid #ddd; }
  /* line 62, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
  .table .table {
    background-color: #fff; }

/* line 75, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px; }

/* line 88, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
.table-bordered {
  border: 1px solid #ddd; }
  /* line 94, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tbody > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > tfoot > tr > td {
    border: 1px solid #ddd; }
  /* line 101, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-bottom-width: 2px; }

/* line 114, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

/* line 125, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

/* line 135, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column; }

/* line 143, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell; }

/* line 9, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5; }

/* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8; }

/* line 9, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #dff0d8; }

/* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6; }

/* line 9, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7; }

/* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3; }

/* line 9, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3; }

/* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc; }

/* line 9, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #f2dede; }

/* line 17, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/mixins/_table-row.scss */
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc; }

/* line 171, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; }
  @media screen and (max-width: 767px) {
    /* line 171, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
    .table-responsive {
      width: 100%;
      margin-bottom: 15px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd; }
      /* line 183, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
      .table-responsive > .table {
        margin-bottom: 0; }
        /* line 191, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
        .table-responsive > .table > thead > tr > th,
        .table-responsive > .table > thead > tr > td,
        .table-responsive > .table > tbody > tr > th,
        .table-responsive > .table > tbody > tr > td,
        .table-responsive > .table > tfoot > tr > th,
        .table-responsive > .table > tfoot > tr > td {
          white-space: nowrap; }
      /* line 200, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
      .table-responsive > .table-bordered {
        border: 0; }
        /* line 208, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
        .table-responsive > .table-bordered > thead > tr > th:first-child,
        .table-responsive > .table-bordered > thead > tr > td:first-child,
        .table-responsive > .table-bordered > tbody > tr > th:first-child,
        .table-responsive > .table-bordered > tbody > tr > td:first-child,
        .table-responsive > .table-bordered > tfoot > tr > th:first-child,
        .table-responsive > .table-bordered > tfoot > tr > td:first-child {
          border-left: 0; }
        /* line 212, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
        .table-responsive > .table-bordered > thead > tr > th:last-child,
        .table-responsive > .table-bordered > thead > tr > td:last-child,
        .table-responsive > .table-bordered > tbody > tr > th:last-child,
        .table-responsive > .table-bordered > tbody > tr > td:last-child,
        .table-responsive > .table-bordered > tfoot > tr > th:last-child,
        .table-responsive > .table-bordered > tfoot > tr > td:last-child {
          border-right: 0; }
        /* line 225, /Users/constant/Repos/agr-website/assets/css/libs/bootstrap/_tables.scss */
        .table-responsive > .table-bordered > tbody > tr:last-child > th,
        .table-responsive > .table-bordered > tbody > tr:last-child > td,
        .table-responsive > .table-bordered > tfoot > tr:last-child > th,
        .table-responsive > .table-bordered > tfoot > tr:last-child > td {
          border-bottom: 0; } }

/*------------------------------------*    #GLOBAL TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*    #FONTS
\*------------------------------------*/
/* line 19, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.font-body, body {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 300; }

/* line 24, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.font-body-italic, em,
i {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 300; }

/* line 30, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.font-body-bold, b,
strong, .slideshow .slideshow__text h1,
.slideshow .slideshow__text h2, .grid__text, .grid-extra-title {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 400; }

/* line 35, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.font-body-bold-italic, em > b,
b > em,
i > b,
b > i,
em > strong,
strong > em,
i > strong,
strong > i {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400; }

/*------------------------------------*    #TYPESIZE/SCALE
\*------------------------------------*/
/*------------------------------------*    #FONTSIZES
\*------------------------------------*/
/*------------------------------------*    #CORE TYPOGRAPHY
\*------------------------------------*/
/* line 92, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 20px;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    /* line 92, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px;
      line-height: 1.6; } }

/* line 101, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p {
  font-size: 13.33333px;
  line-height: 1.6;
  margin-bottom: .5em; }
  @media screen and (min-width: 768px) {
    /* line 101, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    p {
      font-size: 16px;
      line-height: 1.6; } }

/* line 106, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
blockquote p {
  font-size: 20px;
  line-height: 1.6;
  text-align: center; }
  @media screen and (min-width: 768px) {
    /* line 106, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    blockquote p {
      font-size: 24px;
      line-height: 1.6; } }

/* line 111, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
a {
  color: #81C257;
  text-decoration: underline;
  text-decoration-color: #81C257; }
  /* line 96, /Users/constant/Repos/agr-website/assets/css/libs/_mixins.scss */
  a:hover, a:focus, a:active {
    color: #81C257;
    text-decoration: none; }

/* line 122, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.content h2 {
  margin-bottom: 12.5px; }
  @media screen and (min-width: 768px) {
    /* line 122, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    .content h2 {
      margin-bottom: 20px; } }

/* line 125, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.content p + h2 {
  margin-top: 25px; }
  @media screen and (min-width: 768px) {
    /* line 125, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    .content p + h2 {
      margin-top: 40px; } }

/* line 128, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.content h3 {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 6.25px; }
  @media screen and (min-width: 768px) {
    /* line 128, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    .content h3 {
      font-size: 24px;
      line-height: 1.6; } }
  @media screen and (min-width: 768px) {
    /* line 128, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    .content h3 {
      margin-bottom: 10px; } }

/* line 132, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.content p + h3 {
  margin-top: 12.5px; }
  @media screen and (min-width: 768px) {
    /* line 132, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    .content p + h3 {
      margin-top: 20px; } }

/* line 135, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.content p.intro {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 12.5px; }
  @media screen and (min-width: 768px) {
    /* line 135, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    .content p.intro {
      font-size: 24px;
      line-height: 1.6; } }
  @media screen and (min-width: 768px) {
    /* line 135, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    .content p.intro {
      margin-bottom: 20px; } }

/* line 139, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.content p + p.intro {
  margin-top: 12.5px; }
  @media screen and (min-width: 768px) {
    /* line 139, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    .content p + p.intro {
      margin-top: 20px; } }

/* line 165, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p sub, p sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

/* line 172, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p sup {
  top: -0.5em; }

/* line 176, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p sub {
  bottom: -0.25em; }

/* line 180, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p small {
  font-size: 11.66667px;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    /* line 180, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    p small {
      font-size: 14px;
      line-height: 1.6; } }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p abbr {
  border-bottom: 1px dotted #003B59;
  cursor: help; }

/* line 191, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p q:before,
p cite:before {
  content: '"'; }

/* line 194, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p q:after,
p cite:after {
  content: '"'; }

/* line 199, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p mark {
  background-color: #81C257;
  color: white; }

/* line 204, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
p code,
p samp {
  font-family: monospace;
  font-size: 13.33333px;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    /* line 204, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    p code,
    p samp {
      font-size: 16px;
      line-height: 1.6; } }

/* line 210, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
pre {
  font-family: monospace;
  font-size: 13.33333px;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    /* line 210, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    pre {
      font-size: 16px;
      line-height: 1.6; } }

/* line 215, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
hr {
  background: #003B59;
  height: 4px;
  border: none;
  margin: 0; }

/* line 222, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
figcaption {
  font-size: 11.66667px;
  line-height: 1.6;
  margin-top: 3.125px; }
  @media screen and (min-width: 768px) {
    /* line 222, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    figcaption {
      font-size: 14px;
      line-height: 1.6; } }
  @media screen and (min-width: 768px) {
    /* line 222, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    figcaption {
      margin-top: 5px; } }

/* line 228, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
dl dt {
  font-size: 11.66667px;
  line-height: 1.6;
  margin-bottom: 3.125px; }
  @media screen and (min-width: 768px) {
    /* line 228, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    dl dt {
      font-size: 14px;
      line-height: 1.6; } }
  @media screen and (min-width: 768px) {
    /* line 228, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    dl dt {
      margin-bottom: 5px; } }
  /* line 231, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
  dl dt:first-of-type {
    padding-top: 6.25px;
    border-top: 1px #003B59 solid; }
    @media screen and (min-width: 768px) {
      /* line 231, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
      dl dt:first-of-type {
        padding-top: 10px; } }

/* line 236, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
dl dd {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px #003B59 solid; }

/* line 243, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
ol, ul, pre, dl {
  font-size: 13.33333px;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    /* line 243, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    ol, ul, pre, dl {
      font-size: 16px;
      line-height: 1.6; } }

/* line 244, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
ul, ol {
  list-style-type: none; }

/* line 245, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
ul ul {
  font-size: 13.33333px;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    /* line 245, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    ul ul {
      font-size: 16px;
      line-height: 1.6; } }

/* line 246, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
ol ol {
  font-size: 13.33333px;
  line-height: 1.6; }
  @media screen and (min-width: 768px) {
    /* line 246, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
    ol ol {
      font-size: 16px;
      line-height: 1.6; } }

/* line 247, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.text ul {
  list-style-position: outside;
  list-style-type: disc;
  margin-left: 1em; }

/* line 248, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.text ol {
  list-style-position: outside;
  list-style-type: decimal;
  margin-left: 1em; }

/* line 249, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
.text > ul,
.text > ol {
  margin-bottom: 1em; }

/* line 251, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
li {
  line-height: inherit; }

/* line 252, /Users/constant/Repos/agr-website/assets/css/libs/_typography.scss */
input, textarea, label, select {
  line-height: 1.42857; }

/*------------------------------------*    #BOOTSTRAP TYPOGRAPHY
\*------------------------------------*/
/* line 6, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
label, legend, select {
  display: block; }

/* line 10, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 15, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
textarea {
  overflow: auto;
  vertical-align: top; }

/* line 21, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  *margin-top: 0;
  line-height: normal;
  cursor: pointer; }

/* line 30, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto; }

/* line 40, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
select,
input[type="file"] {
  *margin-top: 4px;
  /* In IE7, the height of the select element cannot be changed by height, only font-size */ }

/* line 45, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

/* line 55, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
.form-control {
  transition: border-color 0.3s;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: none;
  padding: 6px; }
  /* line 62, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
  .form-control:focus {
    border-color: #9ACCE2;
    box-shadow: none; }
  /* line 66, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
  .form-control:hover {
    border-color: #999999; }

/* line 71, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
select.form-control {
  appearance: none;
  transition: border-color 0.3s;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE1LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjxwb2x5Z29uIHBvaW50cz0iMTAsMTEuMiA2LjQsNy42IDQuMyw3LjYgMTAsMTMuMyAxNS43LDcuNiAxMy42LDcuNiAiLz4KPC9zdmc+Cg==);
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: auto 16px;
  max-width: 100%; }
  /* line 84, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
  select.form-control:hover {
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
    border-color: #999999; }

/* line 90, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
label {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase; }
  /* line 94, /Users/constant/Repos/agr-website/assets/css/libs/_forms.scss */
  .checkbox label, .radio label {
    font-size: 14px;
    line-height: 1.7; }

/*------------------------------------*    #COLOURS
\*------------------------------------*/
/*------------------------------------*    #BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*    #SPACES
\*------------------------------------*/
/*------------------------------------*    #BORDERS WIDTH
\*------------------------------------*/
/*------------------------------------*    #FUNCTIONAL STUFF
\*------------------------------------*/
/* line 10, /Users/constant/Repos/agr-website/assets/css/libs/_grid.scss */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.5%;
  padding-right: 1.5%; }
  /* line 6, /Users/constant/Repos/agr-website/assets/css/libs/_mixins.scss */
  .container:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 768px) {
    /* line 10, /Users/constant/Repos/agr-website/assets/css/libs/_grid.scss */
    .container {
      width: calc(720px + $grid-gutter-width); } }
  @media (min-width: 992px) {
    /* line 10, /Users/constant/Repos/agr-website/assets/css/libs/_grid.scss */
    .container {
      width: calc(940px + $grid-gutter-width); } }
  @media (min-width: 1200px) {
    /* line 10, /Users/constant/Repos/agr-website/assets/css/libs/_grid.scss */
    .container {
      width: calc(1140px + $grid-gutter-width); } }

/* line 30, /Users/constant/Repos/agr-website/assets/css/libs/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.5%;
  padding-right: 1.5%; }
  /* line 6, /Users/constant/Repos/agr-website/assets/css/libs/_mixins.scss */
  .container-fluid:after {
    content: "";
    display: table;
    clear: both; }

/* line 39, /Users/constant/Repos/agr-website/assets/css/libs/_grid.scss */
.row {
  margin-left: -1.5%;
  margin-right: -1.5%; }
  /* line 6, /Users/constant/Repos/agr-website/assets/css/libs/_mixins.scss */
  .row:after {
    content: "";
    display: table;
    clear: both; }

/* line 135, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .size, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .grid-reduc .size, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .size, .grid-reduc .size, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 1.5%;
  padding-right: 1.5%; }

/* line 151, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-1 {
  width: 8.33333%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-2 {
  width: 16.66667%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-3 {
  width: 25%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-4 {
  width: 33.33333%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-5 {
  width: 41.66667%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-6 {
  width: 50%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-7 {
  width: 58.33333%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-8 {
  width: 66.66667%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-9 {
  width: 75%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-10 {
  width: 83.33333%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-11 {
  width: 91.66667%; }

/* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-12 {
  width: 100%; }

/* line 179, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-0 {
  right: auto; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-1 {
  right: 8.33333%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-2 {
  right: 16.66667%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-3 {
  right: 25%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-4 {
  right: 33.33333%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-5 {
  right: 41.66667%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-6 {
  right: 50%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-7 {
  right: 58.33333%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-8 {
  right: 66.66667%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-9 {
  right: 75%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-10 {
  right: 83.33333%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-11 {
  right: 91.66667%; }

/* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-pull-12 {
  right: 100%; }

/* line 169, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-0 {
  left: auto; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-1 {
  left: 8.33333%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-2 {
  left: 16.66667%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-3 {
  left: 25%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-4 {
  left: 33.33333%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-5 {
  left: 41.66667%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-6 {
  left: 50%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-7 {
  left: 58.33333%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-8 {
  left: 66.66667%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-9 {
  left: 75%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-10 {
  left: 83.33333%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-11 {
  left: 91.66667%; }

/* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-push-12 {
  left: 100%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-0 {
  margin-left: 0%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-3 {
  margin-left: 25%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-6 {
  margin-left: 50%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-9 {
  margin-left: 75%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%; }

/* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  /* line 151, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .size, .grid-reduc .size, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-1 {
    width: 8.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-2 {
    width: 16.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-3 {
    width: 25%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-4 {
    width: 33.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-5 {
    width: 41.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-6, .size, .grid-reduc .size {
    width: 50%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-7 {
    width: 58.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-8 {
    width: 66.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-9 {
    width: 75%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-10 {
    width: 83.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-11 {
    width: 91.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-12 {
    width: 100%; }
  /* line 179, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-0 {
    right: auto; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-1 {
    right: 8.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-2 {
    right: 16.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-3 {
    right: 25%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-4 {
    right: 33.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-5 {
    right: 41.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-6 {
    right: 50%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-7 {
    right: 58.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-8 {
    right: 66.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-9 {
    right: 75%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-10 {
    right: 83.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-11 {
    right: 91.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-pull-12 {
    right: 100%; }
  /* line 169, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-0 {
    left: auto; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-1 {
    left: 8.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-2 {
    left: 16.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-3 {
    left: 25%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-4 {
    left: 33.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-5 {
    left: 41.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-6 {
    left: 50%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-7 {
    left: 58.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-8 {
    left: 66.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-9 {
    left: 75%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-10 {
    left: 83.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-11 {
    left: 91.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-push-12 {
    left: 100%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-0 {
    margin-left: 0%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-3 {
    margin-left: 25%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-6 {
    margin-left: 50%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-9 {
    margin-left: 75%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  /* line 151, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-1, .col-md-2, .col-md-3, .size, .col-md-4, .grid-reduc .size, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-1 {
    width: 8.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-2 {
    width: 16.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-3, .size {
    width: 25%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-4, .grid-reduc .size {
    width: 33.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-5 {
    width: 41.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-6 {
    width: 50%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-7 {
    width: 58.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-8 {
    width: 66.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-9 {
    width: 75%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-10 {
    width: 83.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-11 {
    width: 91.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-12 {
    width: 100%; }
  /* line 179, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-0 {
    right: auto; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-1 {
    right: 8.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-2 {
    right: 16.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-3 {
    right: 25%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-4 {
    right: 33.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-5 {
    right: 41.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-6 {
    right: 50%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-7 {
    right: 58.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-8 {
    right: 66.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-9 {
    right: 75%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-10 {
    right: 83.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-11 {
    right: 91.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-pull-12 {
    right: 100%; }
  /* line 169, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-0 {
    left: auto; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-1 {
    left: 8.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-2 {
    left: 16.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-3 {
    left: 25%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-4 {
    left: 33.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-5 {
    left: 41.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-6 {
    left: 50%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-7 {
    left: 58.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-8 {
    left: 66.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-9 {
    left: 75%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-10 {
    left: 83.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-11 {
    left: 91.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-push-12 {
    left: 100%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-0 {
    margin-left: 0%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-3 {
    margin-left: 25%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-6 {
    margin-left: 50%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-9 {
    margin-left: 75%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  /* line 151, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-1 {
    width: 8.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-2 {
    width: 16.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-3 {
    width: 25%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-4 {
    width: 33.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-5 {
    width: 41.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-6 {
    width: 50%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-7 {
    width: 58.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-8 {
    width: 66.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-9 {
    width: 75%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-10 {
    width: 83.33333%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-11 {
    width: 91.66667%; }
  /* line 159, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-12 {
    width: 100%; }
  /* line 179, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-0 {
    right: auto; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-1 {
    right: 8.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-2 {
    right: 16.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-3 {
    right: 25%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-4 {
    right: 33.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-5 {
    right: 41.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-6 {
    right: 50%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-7 {
    right: 58.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-8 {
    right: 66.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-9 {
    right: 75%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-10 {
    right: 83.33333%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-11 {
    right: 91.66667%; }
  /* line 174, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-pull-12 {
    right: 100%; }
  /* line 169, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-0 {
    left: auto; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-1 {
    left: 8.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-2 {
    left: 16.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-3 {
    left: 25%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-4 {
    left: 33.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-5 {
    left: 41.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-6 {
    left: 50%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-7 {
    left: 58.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-8 {
    left: 66.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-9 {
    left: 75%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-10 {
    left: 83.33333%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-11 {
    left: 91.66667%; }
  /* line 164, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-push-12 {
    left: 100%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-0 {
    margin-left: 0%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-3 {
    margin-left: 25%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-6 {
    margin-left: 50%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-9 {
    margin-left: 75%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/libs/_grid-mixins.scss */
  .col-lg-offset-12 {
    margin-left: 100%; } }

/*------------------------------------*    #PATTERNLAB Layout (à supprimer en production)
\*------------------------------------*/
/* line 6, /Users/constant/Repos/agr-website/assets/css/modules/_00-00-temp-patternlab.scss */
.sg-viewport [role=main] {
  padding: 0.5em; }

/* line 11, /Users/constant/Repos/agr-website/assets/css/modules/_00-00-temp-patternlab.scss */
.sg-main {
  width: 94%;
  margin: auto; }

/* line 16, /Users/constant/Repos/agr-website/assets/css/modules/_00-00-temp-patternlab.scss */
.sg-colors {
  padding-left: 0;
  margin-bottom: 20px;
  font-size: 14px; }
  /* line 20, /Users/constant/Repos/agr-website/assets/css/modules/_00-00-temp-patternlab.scss */
  .sg-colors li {
    width: 10em !important; }
  /* line 21, /Users/constant/Repos/agr-website/assets/css/modules/_00-00-temp-patternlab.scss */
  .sg-colors .sg-swatch {
    height: 9em; }

/*! responsive-nav.js 1.0.39 by @viljamis */
@media (max-width: 767px) {
  /* line 4, /Users/constant/Repos/agr-website/assets/css/modules/_00-01-responsive-nav.scss */
  .nav-collapse ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;
    list-style: none; } }

@media screen and (max-width: 767px) and (max-width: 767px) {
  /* line 12, /Users/constant/Repos/agr-website/assets/css/modules/_00-01-responsive-nav.scss */
  .nav-collapse li {
    width: 100%;
    display: block; } }

@media (max-width: 767px) {
  /* line 19, /Users/constant/Repos/agr-website/assets/css/modules/_00-01-responsive-nav.scss */
  .js .nav-collapse {
    clip: rect(0 0 0 0);
    max-height: 0;
    position: absolute;
    display: block;
    overflow: hidden;
    zoom: 1; }
  /* line 28, /Users/constant/Repos/agr-website/assets/css/modules/_00-01-responsive-nav.scss */
  .nav-collapse.opened {
    max-height: 9999px; }
  /* line 32, /Users/constant/Repos/agr-website/assets/css/modules/_00-01-responsive-nav.scss */
  .nav-toggle {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    display: none; } }

@media screen and (min-width: 768px) {
  /* line 53, /Users/constant/Repos/agr-website/assets/css/modules/_00-01-responsive-nav.scss */
  .nav-toggle {
    display: none; } }

/*------------------------------------*    #HAMBURGER ICON
\*------------------------------------*/
/* line 13, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.2s;
  background-color: transparent; }
  @media (max-width: 767px) {
    /* line 13, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
    .hamburger {
      width: 33.33333px;
      height: 33.33333px; } }

/* line 38, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger:focus {
  outline: none; }

/* line 42, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger span {
  display: block;
  position: absolute;
  top: 24px;
  left: 0px;
  right: 0px;
  height: 2px;
  background: #003B59; }
  @media (max-width: 767px) {
    /* line 42, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
    .hamburger span {
      top: 16px;
      left: 0px;
      right: 0px;
      height: 1.33333px; } }

/* line 58, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger span::before,
.hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #003B59;
  content: ""; }
  @media (max-width: 767px) {
    /* line 58, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
    .hamburger span::before,
    .hamburger span::after {
      height: 1.33333px; } }

/* line 72, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger span::before {
  top: -12px; }
  @media (max-width: 767px) {
    /* line 72, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
    .hamburger span::before {
      top: -8px; } }

/* line 79, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger span::after {
  bottom: -12px; }
  @media (max-width: 767px) {
    /* line 79, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
    .hamburger span::after {
      bottom: -8px; } }

/* line 86, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger--x span {
  transition: background 0s 0.2s; }

/* line 90, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger--x span::before,
.hamburger--x span::after {
  transition-duration: 0.2s, 0.2s;
  transition-delay: 0.2s, 0s; }

/* line 96, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger--x span::before {
  transition-property: top, transform; }

/* line 100, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger--x span::after {
  transition-property: bottom, transform; }

/* active state, i.e. menu open */
/* line 106, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger--x.active span {
  background: none; }

/* line 110, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger--x.active span::before {
  top: 0;
  transform: rotate(45deg); }

/* line 115, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger--x.active span::after {
  bottom: 0;
  transform: rotate(-45deg); }

/* line 120, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger--x.active span::before,
.hamburger--x.active span::after {
  transition-delay: 0s, 0.2s;
  background: #003B59; }

/* line 126, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
.hamburger {
  position: absolute;
  top: 20px;
  right: 0; }
  @media (min-width: 768px) {
    /* line 126, /Users/constant/Repos/agr-website/assets/css/modules/_00-02-hamburger-icon.scss */
    .hamburger {
      display: none; } }

/*! Flickity v2.0.4
http://flickity.metafizzy.co
---------------------------------------------- */
/* line 5, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-enabled {
  position: relative; }

/* line 9, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-enabled:focus {
  outline: none; }

/* line 11, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

/* line 17, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
/* line 25, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 34, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

/* line 40, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
/* line 47, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

/* line 62, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-prev-next-button:hover {
  background: white; }

/* line 64, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

/* line 69, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-prev-next-button:active {
  opacity: 0.6; }

/* line 73, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-prev-next-button.previous {
  left: 10px; }

/* line 74, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
/* line 76, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

/* line 80, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

/* line 85, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto; }

/* line 90, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* line 98, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-prev-next-button .arrow {
  fill: #333; }

/* ---- page dots ---- */
/* line 104, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

/* line 115, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-rtl .flickity-page-dots {
  direction: rtl; }

/* line 117, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

/* line 128, /Users/constant/Repos/agr-website/assets/css/modules/_00-03-flickity.scss */
.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/*------------------------------------*    #ATOMS
\*------------------------------------*/
/*------------------------------------*    #global
\*------------------------------------*/
/*------------------------------------*    #images
\*------------------------------------*/
/* line 19, /Users/constant/Repos/agr-website/assets/css/modules/_01-atoms.scss */
img, video, object {
  max-width: 100%;
  height: auto; }

/* line 24, /Users/constant/Repos/agr-website/assets/css/modules/_01-atoms.scss */
img {
  vertical-align: middle; }

/*------------------------------------*  #ORGANISMS
\*------------------------------------*/
/*------------------------------------*  #GLOBAL
\*------------------------------------*/
/**
 * global > header
 */
/* line 16, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.primary-header {
  position: relative; }

@media (min-width: 768px) {
  /* line 20, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .primary-nav {
    position: absolute !important;
    width: 100%;
    bottom: -10px;
    right: 0; } }

@media (max-width: 767px) {
  /* line 20, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .primary-nav {
    margin-top: 25px; } }
  @media screen and (max-width: 767px) and (min-width: 768px) {
    /* line 20, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .primary-nav {
      margin-top: 40px; } }

/* line 33, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.logo-link {
  width: 150px;
  max-width: 33%;
  display: block; }
  /* line 37, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .logo-link img {
    width: 100%; }

/* line 42, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.menu {
  text-align: right; }
  /* line 44, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .menu li {
    display: inline-block; }
    /* line 46, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .menu li:not(:last-of-type) {
      margin-right: 40px; }
  /* line 50, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .menu a {
    color: #003B59;
    text-decoration: none;
    display: block; }
    /* line 54, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .menu a:hover {
      color: #81C257; }
  /* line 58, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .menu .current-menu-item a,
  .menu .current-page-ancestor a {
    color: #81C257; }
  @media (max-width: 767px) {
    /* line 42, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .menu {
      text-align: center; }
      /* line 64, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
      .menu a {
        border-top: 1px solid #003B59;
        padding-top: 12.5px;
        padding-bottom: 12.5px;
        font-size: 16px; } }
    @media screen and (max-width: 767px) and (min-width: 768px) {
      /* line 64, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
      .menu a {
        padding-top: 20px; } }
    @media screen and (max-width: 767px) and (min-width: 768px) {
      /* line 64, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
      .menu a {
        padding-bottom: 20px; } }
  @media (max-width: 767px) {
      /* line 70, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
      .menu li:last-of-type a {
        border-bottom: 1px solid #003B59; } }

/* line 76, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.hamburger {
  top: 0; }

/**
  * global > footer
  */
/* line 86, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.primary-footer .logo-link {
  width: 80px;
  max-width: 15%;
  display: inline-block;
  opacity: 1;
  transition: opacity .3s ease-in-out; }
  /* line 92, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .primary-footer .logo-link:not(:hover) {
    opacity: .2; }
  /* line 95, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .primary-footer .logo-link img {
    width: 100%; }

/*------------------------------------*  #GALLERY
\*------------------------------------*/
@media (min-width: 768px) {
  /* line 109, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .slideshow {
    height: 45vh; } }

/* line 113, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.slideshow:after {
  /* enable Flickity by default */
  content: 'flickity';
  display: none; }
  @media (max-width: 768px) {
    /* line 113, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .slideshow:after {
      content: '';
      /* disable Flickity for small devices */ } }

/* line 121, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.slideshow .slideshow__item {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (min-width: 768px) {
    /* line 121, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .slideshow .slideshow__item {
      padding: 10%; } }
  @media (max-width: 768px) {
    /* line 121, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .slideshow .slideshow__item {
      display: flex;
      align-items: center;
      height: 150px;
      margin-bottom: 20px; }
      /* line 135, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
      .slideshow .slideshow__item:last-of-type {
        margin-bottom: 0; } }

/* line 141, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.slideshow .slideshow__text {
  display: block;
  position: relative;
  z-index: 2;
  width: 100%; }
  @media (min-width: 768px) {
    /* line 141, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .slideshow .slideshow__text {
      top: 50%;
      transform: translateY(-50%); } }
  /* line 150, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .slideshow .slideshow__text a {
    text-decoration: none; }
  /* line 153, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .slideshow .slideshow__text h1,
  .slideshow .slideshow__text h2 {
    color: white;
    line-height: 1.1;
    text-shadow: 2px 2px 2px black;
    text-align: center; }
  /* line 161, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .slideshow .slideshow__text h1 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 24px; }
    @media (min-width: 768px) {
      /* line 161, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
      .slideshow .slideshow__text h1 {
        font-size: 5vw; } }
  /* line 169, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .slideshow .slideshow__text h2 {
    font-size: 16px; }
    @media (min-width: 768px) {
      /* line 169, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
      .slideshow .slideshow__text h2 {
        font-size: 1.5vw; } }

/* line 181, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.slideshow .dot {
  background-color: #003B59;
  opacity: 1; }
  /* line 184, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .slideshow .dot.is-selected {
    background-color: #81C257;
    opacity: 1; }

/* line 189, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.slideshow .flickity-prev-next-button .arrow {
  fill: #003B59; }

/* line 192, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.slideshow .flickity-prev-next-button {
  width: 35px;
  height: 35px; }

/*------------------------------------*  #SECTION
\*------------------------------------*/
/**
 * section > grid
 */
@media (min-width: 768px) {
  /* line 218, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__item {
    position: relative; }
    /* line 346, /Users/constant/Repos/agr-website/assets/css/libs/_mixins.scss */
    .grid__item:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    /* line 352, /Users/constant/Repos/agr-website/assets/css/libs/_mixins.scss */
    .grid__item > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; } }

/* line 224, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.grid .grid__item {
  opacity: 1;
  transition: opacity .3s ease-in-out;
  margin-bottom: 15%; }
  /* line 227, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid .grid__item:hover {
    opacity: .6; }
  @media (max-width: 991px) {
    /* line 224, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .grid .grid__item {
      margin-bottom: 12.5px; } }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    /* line 224, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .grid .grid__item {
      margin-bottom: 20px; } }

/* line 236, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.grid__wrapper {
  position: relative;
  color: white;
  text-decoration: none; }
  @media (min-width: 768px) {
    /* line 236, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .grid__wrapper {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; } }
  /* line 248, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__wrapper:hover {
    color: white; }

/* line 253, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.grid__text {
  color: white;
  text-align: center;
  padding: 10px;
  word-wrap: break-word;
  font-weight: 700;
  text-transform: uppercase; }
  /* line 261, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__text h2 {
    text-transform: uppercase; }
  /* line 264, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__text h2,
  .grid__text h3 {
    font-size: 13.33333px;
    line-height: 1.6; }
    @media screen and (min-width: 768px) {
      /* line 264, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
      .grid__text h2,
      .grid__text h3 {
        font-size: 16px;
        line-height: 1.6; } }
  /* line 268, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__text h3 {
    font-weight: 400; }
  @media (min-width: 768px) {
    /* line 253, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .grid__text {
      position: relative;
      top: 50%;
      transform: translateY(-50%); } }

/* line 278, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.bg-img .grid__wrapper {
  overflow: hidden; }
  /* line 280, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .bg-img .grid__wrapper:before {
    content: "";
    display: block;
    padding-top: 100%; }

/* line 287, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.bg-img .grid__text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

/* line 296, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.grid__item {
  background-repeat: no-repeat;
  background-size: cover; }
  /* line 297, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__item.blue-1 {
    background-color: #003B59; }
  /* line 300, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__item.blue-2 {
    background-color: rgba(0, 59, 89, 0.3); }
  /* line 303, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__item.green-1 {
    background-color: #81C257; }
  /* line 306, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__item.green-2 {
    background-color: rgba(129, 194, 87, 0.6); }
  /* line 309, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
  .grid__item.green-3 {
    background-color: rgba(129, 194, 87, 0.3); }

/**
  * section > grid detail
  */
/* line 322, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
.grid-extra-title {
  color: #81C257;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.6;
  margin-top: 3.125px; }
  @media screen and (min-width: 768px) {
    /* line 322, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .grid-extra-title {
      font-size: 24px;
      line-height: 1.6; } }
  @media screen and (min-width: 768px) {
    /* line 322, /Users/constant/Repos/agr-website/assets/css/modules/_02-organisms.scss */
    .grid-extra-title {
      margin-top: 5px; } }

/*------------------------------------*    #TEMPLATES
\*------------------------------------*/
/* line 5, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
.container-fluid {
  width: 96%;
  max-width: 1200px;
  margin: auto; }

/* line 10, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
.primary-header {
  margin-top: 25px;
  margin-bottom: 25px; }
  @media screen and (min-width: 768px) {
    /* line 10, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
    .primary-header {
      margin-top: 40px; } }
  @media screen and (min-width: 768px) {
    /* line 10, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
    .primary-header {
      margin-bottom: 40px; } }

/* line 15, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
.primary-footer {
  margin-top: 37.5px;
  margin-bottom: 25px; }
  @media screen and (min-width: 768px) {
    /* line 15, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
    .primary-footer {
      margin-top: 60px; } }
  @media screen and (min-width: 768px) {
    /* line 15, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
    .primary-footer {
      margin-bottom: 40px; } }

/* line 20, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
.content,
.grid-detail__element,
.slideshow {
  margin-bottom: 37.5px; }
  @media screen and (min-width: 768px) {
    /* line 20, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
    .content,
    .grid-detail__element,
    .slideshow {
      margin-bottom: 60px; } }

/* line 27, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
.block-map {
  display: block;
  width: 100%;
  height: 65vh; }
  @media (max-width: 767px) {
    /* line 27, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
    .block-map {
      height: 50vh; } }
  /* line 34, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
  .block-map .block-map__content {
    text-align: center;
    padding: 10px; }
    /* line 37, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
    .block-map .block-map__content p,
    .block-map .block-map__content a {
      font-size: 11.66667px;
      line-height: 1.6;
      color: #003B59; }
      @media screen and (min-width: 768px) {
        /* line 37, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
        .block-map .block-map__content p,
        .block-map .block-map__content a {
          font-size: 14px;
          line-height: 1.6; } }
    /* line 42, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
    .block-map .block-map__content a {
      margin-top: 6.25px; }
      @media screen and (min-width: 768px) {
        /* line 42, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
        .block-map .block-map__content a {
          margin-top: 10px; } }

/* line 49, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
.contact .grid__item {
  margin-bottom: 12.5px; }
  @media screen and (min-width: 768px) {
    /* line 49, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
    .contact .grid__item {
      margin-bottom: 20px; } }

/* line 52, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
.contact a {
  color: #003B59;
  text-decoration: none; }
  /* line 55, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
  .contact a:hover {
    color: #81C257; }

/* line 59, /Users/constant/Repos/agr-website/assets/css/modules/_03-templates.scss */
.contact figure img {
  width: 100%;
  height: auto; }

/*------------------------------------*    #BASE-STYLES
\*------------------------------------*/
/* line 49, /Users/constant/Repos/agr-website/assets/css/screen.scss */
html {
  font-size: 100%; }
  @media screen and (max-width: 767px) {
    /* line 49, /Users/constant/Repos/agr-website/assets/css/screen.scss */
    html {
      -webkit-text-size-adjust: 100%; } }

/* line 56, /Users/constant/Repos/agr-website/assets/css/screen.scss */
body {
  background: white;
  color: #003B59;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*------------------------------------*    #IMPORT-STYLES
\*------------------------------------*/
/*------------------------------------*    #PRINT
\*------------------------------------*/
@media print {
  /* line 79, /Users/constant/Repos/agr-website/assets/css/screen.scss */
  body {
    font-size: 62.5%; } }
