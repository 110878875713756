/*------------------------------------*\
    #GLOBAL TYPOGRAPHY
\*------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,700&subset=latin-ext');


$font-family-body:  "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-var:   Georgia, "Times New Roman", Times, serif !default;
$font-family-mono:  Menlo, Monaco, Consolas, "Courier New", monospace !default;



/*------------------------------------*\
    #FONTS
\*------------------------------------*/


.font-body{
	font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

.font-body-italic{
	font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 300;
}

.font-body-bold{
	font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.font-body-bold-italic{
	font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
}



/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/

$font-size-sm      : 14px;
$font-size-md      : 16px;
$font-size-lg      : 24px;
$font-size-xl      : 30px;

//** Unit-less `line-height`
$line-height      : 1.6;
// for use in components like buttons, labels
$line-height-form : 1.428571429 !default;     // 20/14
$font-res-ratio   : 1.2;                      // responsive ratio



/*------------------------------------*\
    #FONTSIZES
\*------------------------------------*/


$font-sizes-sm: (
  null   : ($font-size-sm / $font-res-ratio, $line-height),
  small   : ($font-size-sm, $line-height),
);

$font-sizes-md: (
  null   : ($font-size-md / $font-res-ratio, $line-height),
  small   : ($font-size-md, $line-height),
);

$font-sizes-lg: (
  null   : ($font-size-lg / $font-res-ratio, $line-height),
  small   : ($font-size-lg, $line-height),
);

$font-sizes-xl: (
  null   : ($font-size-xl / $font-res-ratio, $line-height),
  small   : ($font-size-xl, $line-height),
);



/*------------------------------------*\
    #CORE TYPOGRAPHY
\*------------------------------------*/


h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-size($font-sizes-lg);
}

p {
  @include font-size($font-sizes-md);
  margin-bottom: .5em;
}

blockquote p {
  @include font-size($font-sizes-lg);
  text-align: center;
}

a {
  color: $action;
  text-decoration: underline;
  text-decoration-color: $action;
  @include link-active-styles {   // Mixin for interactions (hover,focus,active)
    color: $action;
    text-decoration: none;
  }
}

.content {
  h2 {
    @include margin-bottom($spacing-md-responsive);
  }
  p + h2 {
    @include margin-top($spacing-lg-responsive);
  }
  h3 {
    @include font-size($font-sizes-lg);
    @include margin-bottom($spacing-sm-responsive);
  }
  p + h3 {
    @include margin-top($spacing-md-responsive);
  }
  p.intro {
    @include font-size($font-sizes-lg);
    @include margin-bottom($spacing-md-responsive);
  }
  p + p.intro {
    @include margin-top($spacing-md-responsive);
  }
}

em,
i{
  @extend .font-body-italic;
}

b,
strong{
  @extend .font-body-bold;
}

em > b,
b > em,
i > b,
b > i,
em > strong,
strong > em,
i > strong,
strong > i {
  @extend .font-body-bold-italic;
}

p sub, p sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

p sup {
  top: -0.5em;
}

p sub {
  bottom: -0.25em;
}

p small{
  @include font-size($font-sizes-sm);
}

p abbr {
  border-bottom: 1px dotted $base-color;
  cursor: help;
}

p q,
p cite{
 &:before{
    content:'"';
 }
 &:after{
    content:'"';
 }
}

p mark{
  background-color: $action;
  color: $body-color;
}

p code,
p samp{
  font-family: monospace;
  @include font-size($font-sizes-md);
}

pre{
  font-family: monospace;
  @include font-size($font-sizes-md);
}

hr{
  background: $base-color;
  height: 4px;
  border: none;
  margin: 0;
}

figcaption{
  @include font-size($font-sizes-sm);
  @include margin-top($spacing-xs-responsive);
}

dl{
  dt{
    @include font-size($font-sizes-sm);
    @include margin-bottom($spacing-xs-responsive);
    &:first-of-type{
      @include padding-top($spacing-sm-responsive);
      border-top: $default-border-width $base-color solid;
    }
  }
  dd{
    padding-bottom: $spacing-sm;
    margin-bottom: $spacing-sm;
    border-bottom: $default-border-width $base-color solid;
  }
}

ol, ul, pre, dl                                         {@include font-size($font-sizes-md);}
ul, ol                                                  {@include nobullet;}
ul ul                                                   {@include font-size($font-sizes-md);}
ol ol                                                   {@include font-size($font-sizes-md);}
.text ul                                                {@include unordered; margin-left: 1em;}
.text ol                                                {@include ordered; margin-left: 1em;}
.text > ul,
.text > ol                                              {margin-bottom: 1em;}
li                                                      {line-height: inherit;}
input, textarea, label, select                          {line-height: $line-height-form}



/*------------------------------------*\
    #BOOTSTRAP TYPOGRAPHY
\*------------------------------------*/


//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  $font-family-body;
$font-family-serif:       $font-family-var;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   $font-family-mono;
$font-family-base:        $font-family-body;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        $line-height-form;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px
