// ------------------------------------------------------------
// Settings

// :: Colours
// :: Breakpoints
// :: Typography
// :: Borders
// :: Spacing

// ------------------------------------------------------------



/*------------------------------------*\
    #COLOURS
\*------------------------------------*/


// Main colour palette
$black            : black;
$white            : white;
$blue             : #003B59;
$green            : #81C257;

$grey             : #999999; // used in forms
$light-grey       : #e0e0e0; // used in forms
$light-blue       : #9ACCE2; // used in forms


// Assign colours to variable roles
// Be descriptive without being specific.
// This allows for consistency project to project, and easy changing

$body-color       : $white;
$base-color       : $blue;
$action           : $green;



/*------------------------------------*\
    #BREAKPOINTS
\*------------------------------------*/


// :: Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  600px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$breakpoints: (
  small : $screen-sm,
);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         3% !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             calc(720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            calc(940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      calc(1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;



/*------------------------------------*\
    #SPACES
\*------------------------------------*/


$spacing-base     : 20px;

$spacing-xxxl     : $spacing-base * 6;  // 120px
$spacing-xxl      : $spacing-base * 4;  // 80px
$spacing-xl       : $spacing-base * 3;  // 60px
$spacing-lg       : $spacing-base * 2;  // 40px
$spacing-md       : $spacing-base;      // 20px
$spacing-sm       : $spacing-base / 2;  // 10px
$spacing-xs       : $spacing-base / 4;  // 5px

$spacing-res-ratio : 1.6;

$spacing-xxxl-responsive: (
  null    : $spacing-xxxl / $spacing-res-ratio,
  small   : $spacing-xxxl,
);

$spacing-xxl-responsive: (
  null    : $spacing-xxl / $spacing-res-ratio,
  small   : $spacing-xxl,
);

$spacing-xl-responsive: (
  null    : $spacing-xl / $spacing-res-ratio,
  small   : $spacing-xl,
);

$spacing-lg-responsive: (
  null    : $spacing-lg / $spacing-res-ratio,
  small   : $spacing-lg,
);

$spacing-md-responsive: (
  null    : $spacing-md / $spacing-res-ratio,
  small   : $spacing-md,
);

$spacing-sm-responsive: (
  null    : $spacing-sm / $spacing-res-ratio,
  small   : $spacing-sm,
);

$spacing-xs-responsive: (
  null    : $spacing-xs / $spacing-res-ratio,
  small   : $spacing-xs,
);


/*------------------------------------*\
    #BORDERS WIDTH
\*------------------------------------*/


$default-border-width         : 1px;



/*------------------------------------*\
    #FUNCTIONAL STUFF
\*------------------------------------*/


@import "functions/functions";
