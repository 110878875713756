/*------------------------------------*\
  #ORGANISMS
\*------------------------------------*/



/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/


/**
 * global > header
 */

.primary-header {
  position: relative;
}

.primary-nav {
  @media (min-width: $screen-sm-min) {
    position: absolute !important; // pour surpaser responsive nav
    width: 100%;
    bottom: -10px;
    right: 0;
  }
  @media (max-width: $screen-xs-max) {
    @include margin-top($spacing-lg-responsive);
  }
}


.logo-link {
  width: 150px;
  max-width: 33%;
  display: block;
  img {
    width: 100%;
  }
}

.menu {
  text-align: right;
  li {
    display: inline-block;
    &:not(:last-of-type) {
      margin-right: $spacing-lg;
    }
  }
  a {
    color: $base-color;
    text-decoration: none;
    display: block;
    &:hover{
      color: $action;
    }
  }
  .current-menu-item a,
  .current-page-ancestor a{
    color: $action;
  }
  @media (max-width: $screen-xs-max) {
    text-align: center;
    a {
      border-top: $default-border-width solid $base-color;
      @include padding-top($spacing-md-responsive);
      @include padding-bottom($spacing-md-responsive);
      font-size: $font-size-md;
    }
    li:last-of-type a {
      border-bottom: $default-border-width solid $base-color;
    }
  }
}

.hamburger {
  top: 0;
}


 /**
  * global > footer
  */

.primary-footer {
  .logo-link {
    width: 80px;
    max-width: 15%;
    display: inline-block;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    &:not(:hover) {
      opacity: .2;
    }
    img {
      width: 100%;
    }
  }
}



/*------------------------------------*\
  #GALLERY
\*------------------------------------*/



.slideshow {
  @media (min-width: $screen-sm-min) {
    height: 45vh;
  }
  &:after { /* enable Flickity by default */
    content: 'flickity';
    display: none;
    @media (max-width: $screen-sm) {
      content: ''; /* disable Flickity for small devices */
    }
  }

  .slideshow__item {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @media (min-width: $screen-sm-min) {
      padding: 10%;
    }
    @media (max-width: $screen-sm) {
      display: flex;
      align-items: center;
      height: 150px;
      margin-bottom: $spacing-md;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }

  .slideshow__text {
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    @media (min-width: $screen-sm-min) {
      top: 50%;
      transform: translateY(-50%);
    }
    a{
      text-decoration: none;
    }
    h1,
    h2 {
      color: $body-color;
      @extend .font-body-bold;
      line-height: 1.1;
      text-shadow: 2px 2px 2px $black;
      text-align: center;
    }
    h1 {
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: $font-size-lg;
      @media (min-width: $screen-sm-min) {
        font-size: 5vw;
      }
    }
    h2 {
      font-size: $font-size-md;
      @media (min-width: $screen-sm-min) {
        font-size: 1.5vw;
      }
    }
  }
}


// flickity stuff
.slideshow {
  .dot {
    background-color: $base-color;
    opacity: 1;
    &.is-selected {
      background-color: $action;
      opacity: 1;
    }
  }
  .flickity-prev-next-button .arrow {
    fill: $base-color;
  }
  .flickity-prev-next-button {
    width: 35px;
    height: 35px;
  }
}



/*------------------------------------*\
  #SECTION
\*------------------------------------*/


/**
 * section > grid
 */

.size {
  @extend .col-sm-6;
  @extend .col-md-3;
 }
.grid-reduc .size {
  @extend .col-sm-6;
  @extend .col-md-4;
}

.grid__item {
  @media (min-width: $screen-sm-min) {
    @include aspect-ratio(1, 1);
  }
}

.grid .grid__item {
  opacity: 1;
  transition: opacity .3s ease-in-out;
  &:hover {
    opacity: .6;
  }
  margin-bottom: 15%;
  @media (max-width: $screen-sm-max) {
    @include margin-bottom($spacing-md-responsive);
  }
}

.grid__wrapper {
  position: relative;
  color: $body-color;
  text-decoration: none;
  @media (min-width: $screen-sm-min) {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &:hover {
    color: $body-color;
  }
}

.grid__text {
  color: white;
  text-align: center;
  padding: $spacing-sm;
  word-wrap: break-word;
  @extend .font-body-bold;
  font-weight: 700;
  text-transform: uppercase;
  h2 {
    text-transform: uppercase;
  }
  h2,
  h3 {
    @include font-size($font-sizes-md);
  }
  h3{
    font-weight: 400;
  }
  @media (min-width: $screen-sm-min) {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.bg-img .grid__wrapper{
  overflow: hidden;
  &:before{
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.bg-img .grid__text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

// colors
.grid__item {
  &.blue-1 {
    background-color: $blue;
  }
  &.blue-2 {
    background-color: rgba($blue, .3);
  }
  &.green-1 {
    background-color: $green;
  }
  &.green-2 {
    background-color: rgba($green, .6);
  }
  &.green-3 {
    background-color: rgba($green, .3);
  }
  background-repeat: no-repeat;
  background-size: cover;
}



 /**
  * section > grid detail
  */

.grid-extra-title {
  color: $green;
  text-transform: uppercase;
  @include font-size($font-sizes-lg);
  @extend .font-body-bold;
  @include margin-top($spacing-xs-responsive);
}
