/*------------------------------------*\
    #PATTERNLAB Layout (à supprimer en production)
\*------------------------------------*/


.sg-viewport [role=main] {
  //max-width: 72em;
  padding: 0.5em;
}

.sg-main{
   width: 94%;
   margin: auto;
}

.sg-colors{
  padding-left: 0;
  margin-bottom: 20px;
  font-size: 14px;
  li{width: 10em !important;}
  .sg-swatch{
    height: 9em;
  }
}
