/*------------------------------------*\
    #TEMPLATES
\*------------------------------------*/

.container-fluid {
  width: 96%;
  max-width: 1200px;
  margin: auto;
}
.primary-header {
  @include margin-top($spacing-lg-responsive);
  @include margin-bottom($spacing-lg-responsive);
}

.primary-footer {
  @include margin-top($spacing-xl-responsive);
  @include margin-bottom($spacing-lg-responsive);
}

.content,
.grid-detail__element,
.slideshow {
  @include margin-bottom($spacing-xl-responsive);
}

// block map
.block-map {
  display: block;
  width: 100%;
  height: 65vh;
  @media (max-width: $screen-xs-max) {
    height: 50vh;
  }
  .block-map__content {
    text-align: center;
    padding: $spacing-sm;
    p,
    a {
      @include font-size($font-sizes-sm);
      color: $base-color;
    }
    a {
      @include margin-top($spacing-sm-responsive);
    }
  }
}

.contact {
  .grid__item {
    @include margin-bottom($spacing-md-responsive);
  }
  a {
    color: $base-color;
    text-decoration: none;
    &:hover {
      color: $action;
    }
  }
  figure img{
    width: 100%;
    height: auto;
  }
}
