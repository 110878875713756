/*------------------------------------*\
    #ATOMS
\*------------------------------------*/



/*------------------------------------*\
    #global
\*------------------------------------*/




/*------------------------------------*\
    #images
\*------------------------------------*/


img, video, object {
  max-width: 100%;
  height: auto;
}

img{
  vertical-align: middle;
}
